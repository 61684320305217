<script>
  // core
  import { onMount, onDestroy, tick } from "svelte";

  // stores
  import { dm1 } from "./stores.js";

  // utils
  import { AlignmentTypeEnum } from "./manager/enum/alignmentTypeEnum.js";

  // input properties
  export let columnConfig;
  export let stationHeaderPosition = undefined;

  // model
  let columns = undefined;
  $: columnConfig, stationHeaderVisibleColumnPositions();
  $: stationHeaderPosition, updateStationHeaderPositionInUrl();

  function updateStationHeaderPositionInUrl() {
    $dm1.stationHeaderPos = stationHeaderPosition;
  }

  function stationHeaderVisibleColumnPositions() {
    let numberOfVisibleColumns = 0;
    columns = [];

    for (let [key, value] of Object.entries(columnConfig)) {
      if (value.show) {
        columns.push({ configName: key, value: value.name });
      }
    }

    if (
      !AlignmentTypeEnum.hasOwnProperty(stationHeaderPosition.toUpperCase())
    ) {
      const isColumnVisible = columns.some(
        column => column.configName === stationHeaderPosition
      );

      const savedStationHeaderPosition = stationHeaderPosition;
      stationHeaderPosition = "left";

      if (isColumnVisible) {
        tick().then(() => {
          stationHeaderPosition = savedStationHeaderPosition;
        });
      }
    }
  }

  onDestroy(() => {
    $dm1.stationHeaderPos = "left";
  });
</script>

<style>
  .divider {
    color: lightgray;
  }
</style>

<div class="field" style="margin-bottom: 0.75rem;">
  <label class="label is-small">Station header position</label>
</div>
<div class="field">
  <div class="control">
    <div class="select is-fullwidth">
      <select bind:value={stationHeaderPosition}>
        {#each Object.keys(AlignmentTypeEnum) as alignmentType, i}
          <option value={alignmentType.toLowerCase()}>
            {AlignmentTypeEnum[alignmentType]}
          </option>
        {/each}
        <option class="divider" disabled>───────────────────</option>
        {#each columns as column, i}
          <option value={column.configName}>{column.value}</option>
        {/each}
      </select>
    </div>
  </div>
</div>
